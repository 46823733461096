.articleContent {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  &-txt {
    width: 50%;
    margin-right: 20px;
    @media screen and (max-width: 780px) {
      margin-right: 0;
      margin-bottom: 20px;
      width: auto;
    }

    h2 {
      margin-bottom: 20px;
    }
    p {
      border: 1px solid #780000;
      padding: 25px;
      color: white;
    }
  }

  &-img {
    width: 50%;
    max-height: 640px;
    overflow: hidden;
    @media screen and (max-width: 780px) {
      width: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 15%;
    }
  }
}

.css-fix {
  white-space: pre-line;
}
