.cardGalleryWrapper {
    width: 300px;
    height: 300px;
    margin: 20px;
    overflow: hidden;
    border-radius: 5px;
    opacity: 0.9;
    box-shadow: 0 0 7px 1px $color1;
    &:hover {
        transition: 350ms ease;
        transform: scale(1.03);
    }
    @media screen and (max-width: 780px) {
        width: auto;
        height: auto;
        border-radius: inherit;
        box-shadow: inherit;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
