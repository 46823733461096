.scroll-top-button {
  font-size: 35px;
  border-radius: 2px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s ease;
  @media only screen and (max-width: 768px) {
    bottom: 15px;
    right: 15px;
  }

  path {
    color: $color3;
  }

  &:hover {
    opacity: 1;
  }
}
