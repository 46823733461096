.tag {
    width: 190px;
    height: 50px;
    margin: 10px;
    @media screen and (max-width: 780px) {
        width: 100%;
    }
    &-txt {
        background: $color6;
        color: #fff;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 780px) {
            text-align: center !important;
            font-size: 12px;
        }
    }
}
