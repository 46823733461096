.formContent {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  height: 300px;
  justify-content: space-between;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    height: inherit;
  }

  &-img {
    width: 45%;
    height: 100%;
    margin-bottom: 120px;

    @media screen and (max-width: 780px) {
      width: 80%;
      margin-bottom: 3rem;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 77%;
    }
  }

  form {
    width: 50%;
    text-align: end;
    @media screen and (max-width: 780px) {
      width: 90%;
      text-align: inherit;
    }

    div {
      width: 100%;

      overflow: hidden;
      margin: 5px 0;
    }

    input {
      height: 30px;
      width: 100%;
      background: $color3;

      color: $color1;
      border: none;
      padding: 0 10px;

      &:focus {
        outline-color: $color3;
        border: 1px solid;
      }

      &::placeholder {
        color: $color1;
      }
    }

    textarea {
      height: 100px;
      width: 100%;
      background: $color3;
      border: none;

      color: $color1;
      padding: 10px;

      &:focus {
        outline-color: $color3;
        border: 1px solid;
      }

      &::placeholder {
        color: $color1;
      }
    }
  }
}

.successMsgContent {
  display: flex;
  height: 200px;
  justify-content: center;

  &-txt {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
