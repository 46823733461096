* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Google Sans", sans-serif;
  font-display: swap;
}

html {
  background-color: $color1;
}

body {
  min-height: 100vh;
  width: 100%;
  padding: 0 50px;
  font-size: 17px;
  @media screen and (max-width: 780px) {
    padding: 0 10px;
  }
}

#CybotCookiebotDialog {
  @media screen and (max-width: 780px) {
    width: 200px !important;
  }
}

ul {
  list-style-type: none;
}

.pageWrapper {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 780px) {
    display: inherit;
  }

  &-content {
    max-width: 1200px;
    width: 100%;
  }
}
