button {
  border: none;
  width: 150px;
  height: 50px;
  background: transparent;
  border: 1px solid $color6;
  @media screen and (max-width: 200px) {
    width: 100%;
  }
  &:hover {
    opacity: 0.8;
    background: $color3;
    color: $color2;
    transition: 0.15s ease;
  }
}
