.cardContent {
  figure {
    display: grid;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
  }
  figure > * {
    grid-area: 1/1;
    transition: 0.4s;
    min-width: 500px;
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  figure figcaption {
    color: white;
    display: grid;
    align-items: end;
    font-family: sans-serif;
    font-size: 2.3rem;
    font-weight: bold;
    padding: 0.75rem;
    background: var(--c, #0009);
    clip-path: inset(0 var(--_i, 100%) 0 0);
    -webkit-mask: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
    -webkit-mask-composite: xor;
    -webkit-mask-clip: text, padding-box;
  }
  figure:hover figcaption {
    --_i: 0%;
  }
  figure:hover img {
    transform: scale(1.2);
  }
  @supports not (-webkit-mask-clip: text) {
    figure figcaption {
      -webkit-mask: none;
      color: #fff;
    }
  }
}
