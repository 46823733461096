.tagContent {
    display: flex;
    margin: 3rem;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (min-width: 780px) and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
    }
    @media screen and (max-width: 780px) {
        margin: 3rem 0;
        align-items: center;
    }
}

.stagesContent {
    background-image: url(../../../public//medias/images/atelier/atelier12.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    &-title {
        display: flex;
        justify-content: center;
        margin: 3rem 0;
        background-color: rgba(112, 121, 140, 0.6);
        height: 150px;
        align-items: center;

        h1 {
            color: $white;
            @media screen and (max-width: 780px) {
                text-align: center;
                font-size: 20px;
            }
        }
    }

    &-txt {
        background-color: rgba(37, 35, 35, 0.6);
        margin: 3rem 0;

        .stagesContent-div {
            color: $white;
            text-align: justify;
            margin: 0 3rem;
            @media screen and (max-width: 780px) {
                margin: 0 1rem;
                font-size: 13px;
            }

            &-prices {
                background-color: rgba(37, 35, 35, 0.6);
                margin: 3rem 0;
                color: #fff;
                display: flex;
                flex-direction: column;
                padding: 10px 60px;
                @media screen and (max-width: 780px) {
                    padding: 10px;
                    font-size: 13px;
                }

                span {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    justify-content: space-between;
                    width: 100%;

                    @media screen and (max-width: 780px) {
                        flex-direction: column;
                    }
                }
            }
        }

        .bg-green {
            background-color: #84a98c !important;
        }
    }
}
