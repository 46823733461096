.products {
  &-gallery {
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: 780px) {
  .productGallery {
    justify-content: center;
  }
}

.metallic-gradient {
  background: linear-gradient(45deg, #70798c, #d4d4d4, #878787, #d4d4d4);
  border-radius: 8px;
  padding: 20px;
  color: #fff; /* Adjust text color as needed */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    pointer-events: none;
  }
}

.productsPage-title {
  margin-bottom: 3rem;
}

.title {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  background: $color2;
  height: 150px;
  align-items: center;
  opacity: 0.9;
  border-radius: 5px;

  @media screen and (max-width: 780px) {
    text-align: center;
    height: auto;
    padding: 10px;
  }

  h1 {
    font-weight: 100;
    font-size: 30px;
    color: #fff;
  }

  h2 {
    width: 80%;
    font-weight: 100;
    color: $color3;
  }
}

.spaceEvenly {
  justify-content: space-evenly;
}

.marginBottom {
  margin-bottom: 10rem;
  @media screen and (max-width: 780px) {
    margin: inherit;
  }
}

.galleryWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: $color2;
  margin-bottom: 20px;
  ul {
    list-style-type: none;
  }
}

.products-linkWrapper {
  width: 30%;
  @media screen and (max-width: 780px) {
    width: auto;
  }
  &-txt {
    margin: 20px 0;
  }
}

.txt {
  border-left: 1px solid #780000;
  line-height: 20px;
  width: 50%;
  margin: 20px 40px;
  padding: 25px;
  @media screen and (max-width: 780px) {
    width: auto;
    margin: 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid #780000;
    border-left: none;
  }

  &-highlight {
    color: $color2;
  }
}
