.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    @media screen and (max-width: 780px) {
        justify-content: flex-end;
    }
    @media screen and (max-width: 280px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    &-brand {
        width: 30px;
        height: 30px;
        @media screen and (min-width: 780px) {
            display: none;
        }
    }

    &-burger {
        width: 100%;
        height: 100%;
        background: #a99985;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 280px) {
            height: inherit;
        }
        svg {
            font-size: 20px;
        }
    }

    &-menu {
        width: 100%;
        z-index: 1;
        a {
            text-decoration: none;
            color: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 780px) {
                justify-content: center;
                width: 100%;
            }
        }
        @media screen and (max-width: 780px) {
            display: none;
            position: relative;
            top: 110px;
        }
        @media screen and (max-width: 280px) {
            position: inherit;
        }
    }

    &-start {
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 780px) {
            flex-direction: column;
            align-items: center;
            background: #f5f1ed;
            color: #a99985;
        }
    }

    &-item {
        font-size: 14px;
        width: 120px;
        height: 50px;

        &:hover {
            opacity: 0.9;
            color: $color6;
            @media screen and (max-width: 780px) {
                background: #252323;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            background-color: $color6;
            position: absolute;
            top: 50px;
            left: 0px;
            color: $color3;
            width: 100%;
            height: 100%;
            list-style-type: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            transition: 500ms ease-in-out;
            @media screen and (max-width: 780px) {
                display: none;
            }
            a:hover {
                opacity: 0.9;
                color: $color5;
            }
        }
    }
}

.test {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    @media screen and (max-width: 780px) {
        align-items: center;
    }
}

.is-active {
    display: initial;
}

.is-inactive {
    display: none;
}
