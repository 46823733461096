.productCard {
  max-width: 500px;
  width: 100%;
  margin: 2rem 1rem;
  border-bottom: 1px solid #a99985;
  overflow: hidden;
  position: relative;

  &__img {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: zoom-in;

    & img {
      width: 100%;
    }

    &-zoom {
      position: fixed;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      max-height: 90%;
      z-index: 9999;
      background-color: $color1;
      padding: 1rem;

      & img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__content {
    padding: 1rem;

    h3 {
      margin-bottom: 1rem;
    }

    &_txt {
      font-size: 14px;
      margin-bottom: 1rem;
    }

    &_price {
      display: flex;
      justify-content: space-between;
      text-align: end;
      align-items: center;

      span:first-child a {
        // border: 1px solid $color6;
        // padding: 1rem;
        // color: red;
        text-decoration: none;
      }
    }

    &_materials {
      display: flex;

      & li {
        border: 1px solid grey;
        max-width: fit-content;
        padding: 0 1rem;
        margin-right: 0.3rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__btn {
    cursor: pointer;
    padding: 0.5rem 0.3rem;
    display: flex;
    align-items: center;
    background: #ff5733;
    border-radius: 1px;

    & a {
      color: black;
    }

    &:hover {
      background-color: black;

      & a {
        color: #ff5733;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .productCard {
    margin: 2rem 0;

    &__img-zoom {
      padding: 0;
    }
  }
}
