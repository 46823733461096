.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-article {
    width: 80%;
    margin: 3em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 780px) {
      width: 100%;
    }

    &-img {
      width: 200px;
      margin: 1em 0;
      @media screen and (min-width: 780px) {
        width: 500px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-txt {
      padding: 10px;
      text-align: center;
      @media screen and (min-width: 780px) {
        max-width: 500px;
        margin: 2em 0;

        h3 {
          margin: 1em 0;
        }
      }
    }

    &-products {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 1em 0;

      &-img {
        width: 200px;
        margin: 1em;
        @media screen and (min-width: 780px) {
          width: 500px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-contact {
      margin-bottom: 15px;
      a {
        text-decoration: none;
        color: $color3;
        border: 1px solid $color6;
        padding: 10px;
      }
    }
  }
}
